import Firebase from './firebase';

class UserStore {

    isLogged() {

        let respuesta = false;
        const user = (localStorage.getItem("userID"));
        if (user) {
            respuesta = true;
        }
        return respuesta;
    }
    getUserName() {
        return localStorage.getItem("userID");
    }
    getFullName(){
        return localStorage.getItem("userName");
    }
     getRole(){
        return localStorage.getItem("userRole");
    }
    
    
    
    cohortSelected() {
        let respuesta = -1;
        const cohort = (localStorage.getItem("currentCohort"));
        if (cohort) {
            respuesta = (localStorage.getItem("currentCohort"));
        }
        return respuesta;
    }
    getUserCohorts() {
        let answer = [];
        const db = Firebase.firestore();
        let users = db.collection('users').doc(this.getUserName());
        let userData = users.get()
            .then(doc => {
                console.log('hola');
                if (!doc.exists) {
                    console.log('No such document!');
                } else {
                    console.log('Document data:', doc.data());
                    console.log(doc.data().name);
                    console.log(doc.data().cohorts);
                    let cohortsReferences = doc.data().cohorts;
                    answer = doc.data().cohorts;
                    console.log('respuesta', answer);
                    return (answer);
                }
            })
            .catch(err => {
                console.log('Error getting document', err);
            });

    }
    async downloadData(nUser) {
        try {
            console.log('hola', nUser);
            const db = Firebase.firestore();

            let users = db.collection('users').doc(nUser);
            let userData = users.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such document!');
                    } else {
                        console.log(doc.data());
                        let userData = doc.data();
                        localStorage.setItem("userName", JSON.stringify(userData.name));
                        localStorage.setItem("userRole", JSON.stringify(userData.role));
                        console.log(localStorage.getItem("userName"));
                        console.log(localStorage.getItem("userRole"));
                    }
                })
                .catch(err => {
                    console.log('Error getting document', err);
                });

        } catch (err) {
            alert(err);
        }
    }
    selectCohort(nSelected) {
        console.log(localStorage.getItem("currentCohort"));
        localStorage.setItem("currentCohort", nSelected);
        console.log(localStorage.getItem("currentCohort"));

    }
}

export default new UserStore();
import React from "react";

import { Layout, Menu } from "antd";

import UserStore from "../utilities/UserStore";
import Firebase from "../utilities/firebase";

import { LogoutOutlined, UserOutlined } from "@ant-design/icons";


const { Header } = Layout;
const { SubMenu } = Menu;

const FullHeader = () => {
  const logout = async () => {
    await Firebase.auth()
      .signOut()
      .then((r) => {
        localStorage.clear();
        window.location.replace("/");
      });
  };
  return (
    <>
      <Header className="header-rm">
        <Menu className="header-menu-rm" mode="horizontal">
          <SubMenu
            key="SubMenu"
            icon={<UserOutlined />}
            title={UserStore.getFullName()}
          >
            <Menu.Item
              onClick={logout}
              icon={<LogoutOutlined />}
              key="setting:2"
            >
              Cerrar sesión
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    </>
  );
};

export default FullHeader;

import React, { useEffect } from "react";
import { Alert, Badge, Collapse, Form, Select, Upload, Button } from "antd";
import Firebase from "../utilities/firebase";
import _ from "lodash";
import UserStore from "../utilities/UserStore";
import { CSVLink, CSVDownload } from "react-csv";

const Downloader = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [formLayout, setFormLayout] = React.useState("horizontal");
  const [fieldsEnable, setFieldsEnable] = React.useState({
    cohort: false,
    evaluation: false,
    upload: false,
  });
  const [fieldsValue, setFieldsValue] = React.useState({
    cohort: 0,
    evaluation: {},
  });
  const [cohorts, setCohorts] = React.useState({});
  const [evaluations, setEvaluations] = React.useState({});
  let [results, setResults] = React.useState([]);

  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const mount = () => {
    const db = Firebase.firestore();
    db.collection("cohorts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let cohortId = doc.id;
          let cohortName = doc.data().characteristics.name;
          let currentObject = cohorts;
          currentObject[cohortId] = cohortName;
          setCohorts({});
          setCohorts(currentObject);
        });
        db.collection("evaluations")
          .get()
          .then((evaluationsSpanshot) => {
            evaluationsSpanshot.forEach((evaluation) => {
              let evaluationId = evaluation.id;
              let evaluationData = evaluation.data();
              evaluationData.information.id = evaluation.id;
              let currentEvaluationsObject = evaluations;
              currentEvaluationsObject[evaluationId] = evaluationData;
              setEvaluations({});
              setEvaluations(currentEvaluationsObject);
            });
            setFieldsEnable({ ...fieldsEnable, cohort: true });
            props.notLoading();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(mount, []);

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const selectCohort = (field) => {
    setFieldsValue({ ...fieldsValue, cohort: field });
    setFieldsEnable({ ...fieldsEnable, evaluation: true });
  };
  const selectEvaluation = (evaluation) => {
    
    setFieldsValue({ ...fieldsValue, evaluation: evaluation=='allParticipants'?'allParticipants':evaluations[evaluation] });
    setFieldsEnable({ ...fieldsEnable, upload: true });
  };
  const downloadButton = async () => {
      let cohortData = fieldsValue.cohort;
      let evaluationData = fieldsValue.evaluation;
      if(evaluationData=='allParticipants'){
          props.itsLoading();
          const db = Firebase.firestore();
          /*db.collection("cohorts").doc(cohortData).collection("participants")
          .get()
          .then((evaluationsSpanshot) => {
            evaluationsSpanshot.forEach(async (evaluation) => {
              
              let participantData = evaluation.data();
              participantData.city = ((await participantData.city.get()).data()).name;
              participantData.currentStatus = ((await participantData.currentStatus.get()).data()).statusLabel;
              participantData.created = participantData.created.toDate();
              participantData.linkedProgramme = participantData.linkedProgramme.toDate();
              setResults([...results,participantData])
              
              
            });
            console.log(results);
            props.notLoading();
          })
          .catch((error) => {
            console.log(error);
          });*/
          let response = [];
          let participantsRef = db.collection("cohorts").doc(cohortData).collection("participants");
          let allParticipants = await participantsRef.get();
          console.log(allParticipants.docs.length);
          /*for(const doc of allParticipants.docs){
            console.log(doc.id, '=>', doc.data());
            let data = doc.data();
            response.push({name: data.name, id: data.id, city: data.city!=''?((await data.city.get()).data()).name:'', currentStatus: data.currentStatus!=''?((await data.currentStatus.get()).data()).statusLabel:''});
          }*/
          //setResults(response);
          console.log(allParticipants.docs);


      }
  }

  return (
    <>
      <Form
        {...formItemLayout}
        layout={formLayout}
        form={form}
        initialValues={{ layout: formLayout }}
        onValuesChange={onFormLayoutChange}
      >
        <Form.Item label="Cohorte">
          <Select
            disabled={!fieldsEnable.cohort}
            onChange={selectCohort}
            placeholder="Por favor seleccionar la cohorte"
          >
            {Object.keys(cohorts).map((key) => (
              <Option key={"cohort" + key} value={key}>
                {cohorts[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Evaluación">
          <Select
            onChange={selectEvaluation}
            disabled={!fieldsEnable.evaluation}
            placeholder="Por favor seleccionar la evaluación"
          >
              <Option key={"evaluation-allParticipants"} value={'allParticipants'}>
                Listado maestro de participantes
              </Option>
            {Object.keys(evaluations).map((key) => (
              <Option key={"evaluation" + key} value={key}>
                {evaluations[key].information.evaluationName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="a">
          
            <Button disabled={!fieldsEnable.upload} onClick={downloadButton}>
              Haz click para descargar
            </Button>
          
        </Form.Item>
      </Form>
      <CSVLink data={results}>Download me</CSVLink>;
    </>
  );
};

export default Downloader;

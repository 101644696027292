import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import UserStore from "./UserStore";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  let history = useHistory();
  useEffect(() => {
    if (!UserStore.cohortSelected()) {
      history.push("/cohort-selection");
    }
  });
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        !UserStore.isLogged() ? (
          <Redirect to="/login" />
        ) : UserStore.cohortSelected() === -1 ? (
          <>
            <Redirect to="/cohort-selection" />
            <Layout>
              <Component {...props} userRole={UserStore.getRole} />
            </Layout>
          </>
        ) : (
          <Layout>
            <Component {...props} userRole={UserStore.getRole} />
          </Layout>
        )
      }
    />
  );
};

export default PrivateRoute;

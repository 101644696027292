import React, { useState, useEffect } from "react";
import { Table, Space, Input, Button} from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";

function SmartTable(props) {
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.cols);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] =  useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    setData(props.data);
    setColumns(props.cols);
  });


  _.forEach(columns, function (col) {
    if (col.shouldFilter) {
      let possibleFilters = [];
      let possibleValues = _.uniq(_.map(data, _.property(col.dataIndex)));
      _.forEach(possibleValues, function (value) {
        possibleFilters.push({ value: value, text: value });
      });
      col.filters = possibleFilters;
      col.onFilter = (value, record) => record[col.dataIndex] == value;
    }
    if (col.shouldSort) {
        col.sorter = (a, b) => {
            console.log(col.dataIndex);
            switch(typeof a[col.dataIndex]){
                case 'number':
                    return a[col.dataIndex] - b[col.dataIndex];
                case 'string':
                    if (a[col.dataIndex].toLowerCase() < b[col.dataIndex].toLowerCase()) {
                        return -1;
                      }
                      if (a[col.dataIndex].toLowerCase() > b[col.dataIndex].toLowerCase()) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                

            }

            console.log(typeof a[col.dataIndex])
        };
        //col.sortOrder = sortedInfo.columnKey === 'name' && sortedInfo.order;
        col.sortDirections = ["descend", "ascend"];
      }
    if (col.shouldSearch) {
        col = _.merge(col, getColumnSearchProps(col));
    }
  });

  function getColumnSearchProps(nCol) {
    let dataIndex = nCol.dataIndex
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
             /*ref={node => {
               searchInput = node;
            }}*/
            placeholder={`Search ${nCol.title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Borrar filtro
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    }
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo({
      filters,
    });
    setSortedInfo(sorter);
  };

  return (
    <>
      <Table pagination={{ current: 1, pageSize: 10}} dataSource={data} columns={columns} onChange={handleChange}  size="small" />
    </>
  );
}

export default SmartTable;

import React, { useCallback, useState } from "react";

import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";

import Firebase from "../utilities/firebase";
import Footer from "../layout/Footer";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import logo from "../assets/brand/rm.png";
const { Title } = Typography;

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [activeFunction, setActiveFunction] = useState("login");
  const [alertBar, setAlertBar] = useState({
    active: false,
    message: "",
    color: "",
  });
  const onReset = async () => {
    console.log(username);
    if (username == "") {
      setAlertBar({
        active: true,
        message: "Debe introducir su correo electrónico",
        color: "danger",
      });
    } else {
      await Firebase.auth()
        .sendPasswordResetEmail(username)
        .then(() => {
          setAlertBar({
            active: true,
            message: "Se ha enviado un correo para reestablecer la contraseña",
            color: "success",
          });
        })
        .catch((error) => {
          let message = "";
          if (error) {
            message = "El formato del correo es invalido";
          } else if ((error.code = "auth/user-not-found")) {
            message = "El usuario no existe en Ruta Motor";
          } else {
            message = error.message;
          }
          setAlertBar({ active: true, message: message, color: "danger" });
        });
    }
  };

  const toggleActive = event => {
    event.preventDefault();
    if (activeFunction === "login") {
      setActiveFunction("reset");
    } else {
      setActiveFunction("login");
    }
  };

  const onFinish = async (values) => {
    if(activeFunction === 'login'){

        try {
            var userInfo = {};
            await Firebase.auth()
              .signInWithEmailAndPassword(values.username, values.password)
              .then((r) => {
                userInfo.id = r.user.uid;
      
                const db = Firebase.firestore();
      
                let users = db.collection("users").doc(r.user.uid);
                let userData = users
                  .get()
                  .then((doc) => {
                    if (!doc.exists) {
                      console.log("No such document!");
                    } else {
                      console.log(doc.data());
                      let userData = doc.data();
                      localStorage.setItem("userID", r.user.uid);
                      localStorage.setItem("userName", userData.name);
                      //localStorage.setItem("userRole", userData.role);
                      localStorage.setItem("userRole", 'admin');
                      window.location.replace("/");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          } catch (error) {
            alert(error);
          }

    }
    else{
        await Firebase.auth()
        .sendPasswordResetEmail(values.username)
        .then(() => {
          setAlertBar({
            active: true,
            message: "Se ha enviado un correo para reestablecer la contraseña",
            color: "success",
          });
        })
        .catch((error) => {
          let message = "";
          if (error) {
            message = "El formato del correo es invalido";
          } else if ((error.code = "auth/user-not-found")) {
            message = "El usuario no existe en Ruta Motor";
          } else {
            message = error.message;
          }
          setAlertBar({ active: true, message: message, color: "danger" });
        });

    }
  };

  return (
    <>
      {alertBar.active ? (
        <>
          <Alert color={alertBar.color}>{alertBar.message}</Alert>
        </>
      ) : (
        <></>
      )}
      <div className="login-rm">
        <div className="container">
          <img
            style={{ width: "20%", height: "auto", paddingTop: "10px", paddingLeft: '10px'}}
            src={logo}
          />
          <Row style={{height: "calc(100vh - 180px)"}} justify="space-around" align="middle">
            <Col span={8}>
              <Card>
                <Form name="basic" onFinish={onFinish} initialValues={{ remember: true }}>
                  <Title level={5}>{activeFunction === 'login' ?'Ingresa tus credenciales para ingresar':'Ingresa tu correo para reestablecer tu contraseña'}</Title>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor digita tu usuario!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Usuario"
                    />
                  </Form.Item>
                  {activeFunction === 'login'&&(<>
                    <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor digita tu contraseña!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Contraseña"
                    />
                  </Form.Item>
                  </>)}
                  
                  <Form.Item>
                    <a onClick={toggleActive} className="login-form-forgot" href="">
                    {activeFunction === 'login' ?'¿Olvidaste tu contraseña?':'Volver al login'}
                    </a>
                  </Form.Item>
                  <Form.Item className="last-item">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                       {activeFunction === 'login' ?'Iniciar sesión':'Reestablecer contraseña'}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LoginPage;

import React, { useEffect } from "react";
import { Alert, Badge, Collapse, Form, Select, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as CSV from "csv-string";
import Firebase from "../utilities/firebase";
import SmartTable from "../components/SmartTable";
import _ from "lodash";
import UserStore from "../utilities/UserStore";
const { Panel } = Collapse;

const UploadComponent = (props) => {
  const [toBeUploaded, setToBeUploaded] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("horizontal");
  const [cohorts, setCohorts] = React.useState({});
  let cities;
  const [evaluations, setEvaluations] = React.useState({});
  const [fieldsEnable, setFieldsEnable] = React.useState({
    cohort: false,
    evaluation: false,
    upload: false,
  });
  const [fieldsValue, setFieldsValue] = React.useState({
    cohort: 0,
    evaluation: {},
  });
  const [alert, setAlert] = React.useState({ active: false, error: "" });
  const [allParticipants, setAllParticipants] = React.useState([]);
  const [participantsOnce, setParticipantsOnce] = React.useState([]);
  const [rawData, setRawData] = React.useState("");
  const [form] = Form.useForm();
  const { Option } = Select;

  const mount = () => {
    const db = Firebase.firestore();
    db.collection("cohorts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let cohortId = doc.id;
          let cohortName = doc.data().characteristics.name;
          let currentObject = cohorts;
          currentObject[cohortId] = cohortName;
          setCohorts({});
          setCohorts(currentObject);
        });
        db.collection("evaluations")
          .get()
          .then((evaluationsSpanshot) => {
            evaluationsSpanshot.forEach((evaluation) => {
              let evaluationId = evaluation.id;
              let evaluationData = evaluation.data();
              evaluationData.information.id = evaluation.id;
              let currentEvaluationsObject = evaluations;
              currentEvaluationsObject[evaluationId] = evaluationData;
              setEvaluations({});
              setEvaluations(currentEvaluationsObject);
            });
            setFieldsEnable({ ...fieldsEnable, cohort: true });
            props.notLoading();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(mount, []);
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      }
      : null;

  const uploadConfig = {
    accept: ".txt, .csv",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLoading(true);
        let info = e.target.result;
        comparisonFunction(info);
      };
      reader.readAsText(file);

      return false;
    },
  };

  const notLoading = () => {
    setLoading(false);
  };

  const processCSV = async (nRawData) => {
    return await CSV.parse(nRawData);
  };

  const allParticipantsDownload = async () => {
    let answer = [];
    const db = Firebase.firestore();
    const participantsRef = db
      .collection("cohorts")
      .doc(fieldsValue.cohort)
      .collection("participants");
    const snapshot = await participantsRef.get();
    snapshot.forEach((doc) => {
      answer.push(doc.id);
    });
    return answer;
  };

  const frequentParticipantsDownload = async () => {
    let answer = [];
    const db = Firebase.firestore();
    const evaluationsRef = db
      .collection("cohorts")
      .doc(fieldsValue.cohort)
      .collection("evaluations");
    const snapshot = await evaluationsRef
      .where("information.typeId", "==", fieldsValue.evaluation.information.id)
      .get();

    await Promise.all(
      snapshot.docs.map(async (doc) => {
        const contents = await doc.data().information.participantId.get();
        answer.push(contents.id);
      })
    );
    return answer;
  };

  const valueIsValid = (originalField, uploadedField) => {
    let answer = false;
    let error = "";
    if (uploadedField != "") {
      switch (originalField.type) {
        case "text":
        case "textarea":
          originalField.value = uploadedField;
          answer = true;
        case "number":
          answer = !isNaN(uploadedField);
          if (!isNaN(uploadedField)) {
            originalField.value = uploadedField;
          } else
            error = "El tipo de dato no es númerico";
          break;
        case "email":
          break;
        case "date":
          if (isDate(uploadedField)) {
            answer = true;
            originalField.value = uploadedField;
          } else {
            error = "el formato de fecha no es correcto.";
          }
          break;
        case "city":
          if (_.some(cities, { name: uploadedField })) {
            answer = true;
            originalField.valueCity = uploadedField;
          } else {
            error = "no está incluido en las opciones de respuesta.";
          }
          break;
        case "checkbox":
          let answers = uploadedField.split(",");
          let values = [];
          _.forEach(answers, function (currentAnswer) {
            let seeker = _.filter(originalField.options, [
              "value",
              currentAnswer,
            ]);
            if (seeker.length == 1) {
              values.push(seeker[0].key);
            } else if (seeker.length > 1) {
              error = "corresponde a más de una respuesta.";
            } else if (seeker.length == 0) {
              error = "no está incluido en las opciones de respuesta.";
            }
          });
          if (values.length > 0) {
            answer = true;
            originalField.value = values;
          }
          break;
        case "select":
        case "radio":
          let seeker = _.filter(originalField.options, ["value", uploadedField]);
          if (seeker.length == 1) {
            answer = true;
            originalField.value = seeker[0].key;
          }
          else if (seeker.length > 1) {
            error = "corresponde a más de una respuesta.";
          }
          else if (seeker.length == 0) {
            error = "no está incluido en las opciones de respuesta.";
          }
          break;
        default:
          break;
      }
    } else {
      error = "está vacio";
    }
    return [answer, error, originalField];
  };

  const isDate = (sDate) => {
    if (sDate.toString() == parseInt(sDate).toString()) return false;
    var tryDate = new Date(sDate);
    return (tryDate && tryDate.toString() != "NaN" && tryDate != "Invalid Date");
  }

  const comparisonFunction = async (rawData) => {
    const tempToBeUploaded = await processCSV(rawData);
    if (tempToBeUploaded.length > 1) {
      let headers = tempToBeUploaded[0];
      let content = _.slice(tempToBeUploaded, 1);

      if (_.includes(headers, "idParticipante")) {
        let objectArray = [];
        //If activity has city field load cities array
        if (_.some(fieldsValue.evaluation[1], { type: "city" })) {
          let citiesFetch = await (await import("cities.json")).default;

          cities = _.map(
            _.orderBy(_.filter(citiesFetch, { country: "CO" }), "name"),
            (city) => _.extend({ value: city.name }, city)
          );
        }

        //TODO: mirar si es necesario bajar todos los participantes para no saturar firebase
        const tempAllParticipants = await allParticipantsDownload();
        let frequentParticipants = [];

        if (fieldsValue.evaluation.information.evaluationFrequency === "once") {
          frequentParticipants = await frequentParticipantsDownload();
        }

        _.forEach(content, function (row) {
          let newObject = _.zipObject(headers, row);
          newObject.canBeUploaded =
            _.includes(tempAllParticipants, newObject.idParticipante) &&
            !_.includes(frequentParticipants, newObject.idParticipante);
          newObject.error = !_.includes(
            tempAllParticipants,
            newObject.idParticipante
          )
            ? "El participante no existe"
            : _.includes(frequentParticipants, newObject.idParticipante)
              ? "El participante ya tiene esta evaluación"
              : "";
          newObject.status = "notUploaded";
          if (newObject.canBeUploaded) {
            let targetFields =
              fieldsValue.evaluation[
              fieldsValue.evaluation.information.currentVersion
              ];
            let mandatoryFields = _.pickBy(targetFields, {
              visibilityDependant: false,
            });
            let dependantFields = _.pickBy(targetFields, {
              visibilityDependant: true,
            });
            if (
              _.intersection(_.keys(newObject), _.keys(mandatoryFields))
                .length >= _.keys(mandatoryFields).length
            ) {
              let items = mandatoryFields;

              _.forEach(items, function (currentItem, key) {
                let validTest = valueIsValid(currentItem, newObject[key]);
                let valid = validTest[0];
                let fieldError = validTest[1];
                currentItem = validTest[2];
                if (valid) {
                } else {
                  newObject.canBeUploaded = false;
                  newObject.error =
                    newObject.error +
                    "El valor del campo " +
                    key +
                    " tiene el siguiente error: " +
                    fieldError +
                    ".";
                }
              });

              if (newObject.canBeUploaded) {
                //Upload new evaluations
                const uploadId = newTask(newObject);
                newObject.status = "Uploaded";
              }
            } else {
              newObject.canBeUploaded = false;
              newObject.error =
                "Faltan los siguiente(s) campo(s) obligatorios: " +
                _.xor(
                  _.keys(mandatoryFields),
                  _.intersection(_.keys(newObject), _.keys(mandatoryFields))
                );
            }
          }
          objectArray.push(newObject);
        });
        setToBeUploaded(objectArray);
      } else {
        setAlert({
          active: true,
          error: "Columna idParticipante no encontrada",
        });
      }
    } else {
      setAlert({
        active: true,
        error: "El archivo no tiene participantes",
      });
    }

    notLoading();
  };

  const newTask = async (newTask) => {
    let db = Firebase.firestore();
    let creatorRef = await db.doc("users/" + UserStore.getUserName());
    let participantRef = await db.doc(
      "cohorts/" +
      UserStore.cohortSelected() +
      "/participants/" +
      _.toString(newTask.idParticipante)
    );

    let evaluationToUpload = {
      items: fieldsValue.evaluation[fieldsValue.evaluation.information.currentVersion], information: fieldsValue.evaluation.information
    };

    evaluationToUpload.information = {
      ...evaluationToUpload.information,
      created: Firebase.firestore.Timestamp.fromDate(new Date()),
      createdBy: creatorRef,
      finished: false,
      participantId: participantRef,
      started: false,
      status: "Nueva tarea",
      typeId: fieldsValue.evaluation.information.id,
    };

    let uploaded = await db.collection('cohorts').doc(fieldsValue.cohort).collection('evaluations').add(evaluationToUpload);
    return uploaded.id;
  }

  const selectCohort = (field) => {
    setFieldsValue({ ...fieldsValue, cohort: field });
    setFieldsEnable({ ...fieldsEnable, evaluation: true });
  };

  const selectEvaluation = (evaluation) => {
    setFieldsValue({ ...fieldsValue, evaluation: evaluations[evaluation] });
    setFieldsEnable({ ...fieldsEnable, upload: true });
  };

  return (
    <>
      {loading && (
        notLoading()
      )}
      {alert.active && (
        <>
          <Alert
            message="Error"
            description={alert.error}
            type="error"
            showIcon
          />
        </>
      )}
      <Form
        {...formItemLayout}
        layout={formLayout}
        form={form}
        initialValues={{ layout: formLayout }}
        onValuesChange={onFormLayoutChange}
      >
        <Form.Item label="Cohorte">
          <Select
            disabled={!fieldsEnable.cohort}
            onChange={selectCohort}
            placeholder="Por favor seleccionar la cohorte"
          >
            {Object.keys(cohorts).map((key) => (
              <Option key={"cohort" + key} value={key}>
                {cohorts[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Evaluación">
          <Select
            onChange={selectEvaluation}
            disabled={!fieldsEnable.evaluation}
            placeholder="Por favor seleccionar la evaluación"
          >
            {Object.keys(evaluations).map((key) => (
              <Option key={"evaluation" + key} value={key}>
                {evaluations[key].information.evaluationName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Archivo CSV">
          <Upload {...uploadConfig}>
            <Button disabled={!fieldsEnable.upload} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>
      <Collapse defaultActiveKey={["1", "2"]}>
        <Panel
          header="This is panel header 1"
          key="1"
          extra={
            <Badge
              style={{ backgroundColor: "#52c41a" }}
              count={_.size(_.filter(toBeUploaded, { canBeUploaded: true }))}
            />
          }
        >
          <SmartTable
            data={_.filter(toBeUploaded, { canBeUploaded: true })}
            cols={[
              {
                title: "Identificación",
                dataIndex: "idParticipante",
                key: "id",
              },
              {
                title: "Status",
                dataIndex: "canBeUploaded",
                key: "canBeUploaded",
              },
              {
                title: "¿Subido al sistema?",
                dataIndex: "status",
                key: "status",
              },
            ]}
          />
        </Panel>
        <Panel
          header="This is panel header 2"
          key="2"
          extra={
            <Badge
              count={_.size(_.filter(toBeUploaded, { canBeUploaded: false }))}
            />
          }
        >
          <SmartTable
            data={_.filter(toBeUploaded, { canBeUploaded: false })}
            cols={[
              {
                title: "Identificación",
                dataIndex: "idParticipante",
                key: "id",
              },
              {
                title: "Status",
                dataIndex: "canBeUploaded",
                key: "canBeUploaded",
              },
              { title: "Error", dataIndex: "error", key: "error" },
            ]}
          />
        </Panel>
      </Collapse>
    </>
  );
};
export default UploadComponent;
import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import 'ant-design-pro/dist/ant-design-pro.css';
import "./index.css";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import PrivateRoute from "./utilities/PrivateRoute";
import PublicRoute from "./utilities/PublicRoute";
import Main from "./layout/Main";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import CohortSelection from "./pages/CohortSelection";
import Participants from './pages/Participants';
import ParticipantDetails from './pages/ParticipantDetails';
import Evaluation from './pages/Evaluation';
import Uploader from './pages/Uploader';
import UploadPage from './pages/UploadPage';
import DownloadPage from './pages/DownloadPage';


ReactDOM.render(
  
    <BrowserRouter>
      <Switch>
      <PrivateRoute component={Dashboard} layout={Main} path="/dashboard" exact />
      <PrivateRoute component={CohortSelection} layout={Main} path="/cohort-selection" exact />
      <PrivateRoute component={Participants} layout={Main} path="/participants" exact />
      <PrivateRoute component={ParticipantDetails} layout={Main} path="/participant-details" exact />
      <PrivateRoute component={Evaluation} layout={Main} path="/evaluation" exact />
      <PrivateRoute component={Uploader} layout={Main} path="/uploader" exact />
      <PrivateRoute component={UploadPage} layout={Main} path="/upload" exact />
      <PrivateRoute component={DownloadPage} layout={Main} path="/download" exact />
      <PublicRoute restricted={true} path="/login" component={Login} exact />
      <Redirect from="/" to="/cohort-selection" />
      </Switch>
    </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

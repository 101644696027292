import React, { useState, useEffect } from "react";
import UserStore from "../utilities/UserStore";
import Firebase from "../utilities/firebase";
import { Card, Col, Descriptions, Row } from "antd";
import Moment from 'react-moment';

const CohortSelection = (props) => {
  props.hasTitle("Selección de cohorte");
  const [cohorts, setCohorts] = useState([]);

  const mount = () => {
    const db = Firebase.firestore();

    let users = db.collection("users").doc(UserStore.getUserName());
    users.get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          let allCohorts = doc.data().cohorts;
          allCohorts.forEach((cohort) => {
            cohort.get().then((doc1) => {
              let temp = cohorts;
              let currentDoc = doc1.data();
              currentDoc.id = doc1.id;
              temp.push(currentDoc);
              setCohorts("");
              setCohorts(temp);
            });
            props.notLoading();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };
  useEffect(mount, []);

  const handleClick = (data) =>{
    UserStore.selectCohort(data);
    window.location.href = "/participants";
  } 



  return (
    <>
    <Row gutter={[16, 16]}>
      {cohorts.length > 0
        ? cohorts.map((current) => (
            <>
            <Col key={'col'+current.id} span={8}>
              <Card key={current.id} onClick={() => handleClick(current.id)}>
                <Descriptions title={current.characteristics.name}>
                  <Descriptions.Item span={3} label="Fecha inicio">
                  <Moment local format="D MMM YYYY">{current.characteristics.startDate
                      .toDate()}
                      </Moment>
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label="Fecha fin">
                  <Moment local format="D MMM YYYY">{current.characteristics.endDate
                      .toDate()}
                      </Moment>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              </Col>
            </>
          ))
        : ""}
        </Row>
    </>
  );
};

export default CohortSelection;

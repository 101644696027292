import React, { useState } from "react";

import {
  Alert,
  Avatar,
  Badge,
  Drawer,
  Layout,
  Menu,
  PageHeader,
  Spin,
} from "antd";

import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import logo from "../assets/brand/rm.png";

import Header from "./Header";
import Footer from "./Footer";

const { Sider, Content } = Layout;

//TODO: Make the sider collapsible

const Main = (props) => {
  const [loading, setLoading] = useState(true);
  const [help, setHelp] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [title, setTitle] = useState(false);
  const [helpContent, setHelpContent] = useState();
  const [pageTitle, setPageTitle] = useState("");
  const [pageOptions, setPageOptions] = useState();
  const [alerting, setAlerting] = useState(false);
  const [alertOptions, setAlertOptions] = useState({ type: "error", message: "hola" });

  const hasAlert = (alertContent) => {
    setAlertOptions(alertContent);
    setAlerting(true);
  };

  const hasHelp = (helpContent) => {
    setHelp(true);
    setHelpContent(helpContent);
  };

  const hasTitle = (nTitle, nOptions) => {
    setTitle(true);
    setPageTitle(nTitle);
    setPageOptions(nOptions);
  };

  const noTitle = () => {
    setTitle(false);
    setPageTitle('');
    setPageOptions('');
  }

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };

  const itsLoading = () => {
    setLoading(true);
  };

  const notLoading = () => {
    setLoading(false);
  };

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        noTitle: noTitle,
        hasTitle: hasTitle,
        hasHelp: hasHelp,
        hasAlert: hasAlert,
        itsLoading: itsLoading,
        notLoading: notLoading,
        loadingStatus: loading,
      });
    }
    return child;
  });

  return (
    <>
      <Layout>
        <Sider breakpoint="md" collapsedWidth="0" className="sider-rm">
          <div className="logo-box-rm">
            <img alt="Logo Ruta Motor" className="logo-rm" src={logo} />
          </div>
          <Menu defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item key="1" icon={<QuestionCircleOutlined />}>
              <a href="/cohort-selection">Selección de ciclo</a>
            </Menu.Item>
          </Menu>
        </Sider>
        <Drawer
          title="Ayuda"
          placement="right"
          closable={true}
          onClose={onClose}
          visible={drawerVisible}
        >
          {helpContent}
        </Drawer>
        <Layout className="site-layout-rm">
          <Header>Header</Header>

          {help && (
            <>
              <div className="fixed-widgets">
                <Badge dot>
                  <Avatar
                    size={40}
                    icon={<QuestionCircleOutlined />}
                    onClick={showDrawer}
                  />
                </Badge>
              </div>
            </>
          )}
          {loading && (
            <>
              <Spin
                className="loading-rm"
                indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
              >
                {" "}
              </Spin>
            </>
          )}
          {alerting && (
            <>
              <Alert
                type={alertOptions.type}
                message={alertOptions.message}
                banner
                closable
                showIcon
                style={{ zIndex: "100" }}
              />
            </>
          )}
          <Content className={"site-layout-background content-layout-rm"}>
            {title && (
              <>
                <PageHeader
                  className="site-page-header-responsive page-header-rm"
                  onBack={() => window.history.back()}
                  title={pageTitle}
                  extra={pageOptions}
                />
              </>
            )}
            {childrenWithProps}
          </Content>
          <Footer className="footer-rm" />
        </Layout>
      </Layout>
    </>
  );
};

export default Main;

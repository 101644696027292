import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Firebase from "../utilities/firebase";
import UserStore from "../utilities/UserStore";
import SmartTable from "../components/SmartTable";
import SmarterTableComplete from "../components/SmarterTableComplete";
import NewParticipant from "../components/NewParticipant";
import { Button, Dropdown, Empty, Modal, Table, Form, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getProgressStatus } from "@ant-design/pro-field/lib/components/Progress";

const Participants = (props) => {
  let [data, setData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lastVisible, setLastVisible] = useState(false);
  const [firstVisible, setFirstVisible] = useState(false);
  const [querySnapshotAux, setQuerySnapshotAux] = useState(null);
  let [isLastPage, setIsLastPage] = useState(null);
  let [currentPage, setCurrentPage] = useState(1);
  let [listStatus, setListStatus] = useState([]);
  const formRef = useRef(null);
  const numberOfRecords = 10;
  const { Option } = Select;

  const button = () => {
    return (
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        shape="circle"
        icon={<PlusOutlined />}
      />
    );
  };

  const mount = async () => {
    props.hasTitle("Participantes de la cohorte", button);
    getParticipants('first');
    getStatus();
  };

  useEffect(mount, []);

  const addParticipant = (participantObject) => {
    // console.log(participantObject);
    let temporaryParticipant = participantObject;

    let currentParticipant = {
      city: temporaryParticipant.city,
      currentStatus: temporaryParticipant.currentStatus,
      id: temporaryParticipant.id,
      name: temporaryParticipant.name,
      keyWordsName: processArrayName(temporaryParticipant.name.toLowerCase()),
    };
    try {
      currentParticipant.currentStatus.get().then((status) => {
        let currentStatus = status.data();
        let statusName = currentStatus.statusLabel;
        currentParticipant.currentStatus = statusName;
        currentParticipant.statusReference = status.ref.path;
        currentParticipant.statusActive = currentStatus.active;
        try {
          currentParticipant.city.get().then((city) => {
            currentParticipant.city = city.data();
            let joined = data;
            joined.push(currentParticipant);
            setData([]);
            setData(joined);
          });

        } catch(error){
          console.log('error con ciudad:' + error);
        }
        
      });
      
    } catch (error) {
      console.log('error con participante:' + error);
    }
    
  };

  const tableColumns = [
    {
      title: "Identificación",
      dataIndex: "id",
      key: "id",

      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",

      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: "Ciudad",
      dataIndex: ["city", "name"],
      key: "city",
    },
    {
      title: "Estado",
      dataIndex: "currentStatus",
      key: "currentStatus",

      shouldFilter: true,
      shouldSort: true,
    },
    {
      title: "Action",
      dataIndex: "render",
      key: "x",
      render: (record) => (
        <Link
          to={{
            pathname: "/participant-details",
            state: { participant: record.id },
          }}
        >
          <Button color="primary" size="sm">
            {" "}
            Detalles{" "}
          </Button>

        </Link>

      ),
    },
  ];

  const filterForm = () => {
    formRef.current.submit();
  };

  const cleanFilters = () => {
    formRef.current.resetFields();
    getParticipants('first');
  };

  const onFinish = async (values) => {
    getParticipants('first');
  };

  const getParticipants = (navigation) => {
    let userRole = UserStore.getRole();
    const db = Firebase.firestore();
    var query = null;

    switch (navigation) {
      case 'first':
        currentPage = 1;
        setCurrentPage(currentPage);
        if (userRole == "admin") {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("participants");
        }
        else {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("assignation")
            .doc(UserStore.getUserName())
            .collection("assignees");
        }
        break;
      case 'next':
        currentPage = currentPage + 1;
        setCurrentPage(currentPage);

        if (userRole == "admin") {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("participants");
        }
        else {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("assignation")
            .doc(UserStore.getUserName())
            .collection("assignees");
        }
        break;
      case 'prev':
        currentPage = currentPage - 1;
        setCurrentPage(currentPage);

        if (userRole == "admin") {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("participants");
        }
        else {
          query = db.collection("cohorts")
            .doc(UserStore.cohortSelected())
            .collection("assignation")
            .doc(UserStore.getUserName())
            .collection("assignees");
        }
        break;
    }

    processQuery(query, navigation);
  }

  const getStatus = () => {
    const db = Firebase.firestore();
    db.collection("status").get().then((querySnapshot) => {
      let listStatus = [];
      let itemStatusAux = {};
      querySnapshot.docs.forEach(function (doc) {
        itemStatusAux = {
          ...doc.data(),
          id: doc.id
        };

        listStatus.push(itemStatusAux);
      });
      setListStatus(listStatus);
    });

  }

  const processQuery = async (query, navigation) => {
    props.itsLoading();
    data = [];
    setData(data);

    //Add filter by name
    if (formRef.current.getFieldValue().name != undefined && formRef.current.getFieldValue().name != "") {
      query = query
        .where('keyWordsName', 'array-contains', formRef.current.getFieldValue().name.toLowerCase());
    }

    //Add filter by id
    if (formRef.current.getFieldValue().document != undefined && formRef.current.getFieldValue().document != "") {
      if (formRef.current.getFieldValue().document.length >= 10) {
        query = query
          .where('id', '==', Number(formRef.current.getFieldValue().document));
      } else if (formRef.current.getFieldValue().document.length < 10 && (formRef.current.getFieldValue().name == undefined || formRef.current.getFieldValue().name == "")) {
        let objCalculate = calculateFilterId();
        query = query
          .where('id', '>=', Number(objCalculate.min))
          .where('id', '<', Number(objCalculate.max));
      }
    }

    //Add filter by status
    if (formRef.current.getFieldValue().status != undefined && formRef.current.getFieldValue().status != "") {
      const db = Firebase.firestore();
      let statusRef = db.doc("status/" + formRef.current.getFieldValue().status);

      query = query
        .where('currentStatus', '==', statusRef);
    }

    if (formRef.current.getFieldValue().name == undefined && formRef.current.getFieldValue().document == undefined) {
      switch (navigation) {
        case 'first':
          query = query
            .orderBy("created", "desc")
            .limit(numberOfRecords);
          break;
        case 'next':
          query = query
            .orderBy("created", "desc")
            .startAfter(lastVisible)
            .limit(numberOfRecords);
          break;
        case 'prev':
          query = query
            .orderBy("created", "desc")
            .endBefore(firstVisible)
            .limitToLast(numberOfRecords);
          break;
      }
    }
    else {
      switch (navigation) {
        case 'first':
          query = query
            .limit(numberOfRecords);
          break;
        case 'next':
          query = query
            .startAfter(lastVisible)
            .limit(numberOfRecords);
          break;
        case 'prev':
          query = query
            .endBefore(firstVisible)
            .limitToLast(numberOfRecords);
          break;
      }
    }

    let userRole = UserStore.getRole();
    if (userRole == "admin") {
      if (!isLastPage) {
        await query.get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              props.notLoading();
              setEmptyList();
            } else {
              let hasParticipants = false;

              querySnapshot.forEach((doc) => {
                let temporaryParticipant = doc.data();
                if (validateFilter(temporaryParticipant)) {
                  hasParticipants = true;
                  temporaryParticipant.id = doc.id;
                  addParticipant(temporaryParticipant);
                }
              });

              if (hasParticipants) {
                setQuerySnapshotAux(querySnapshot);
              }
              else
                setEmptyList();

              props.notLoading();
              setIsEmpty(!hasParticipants);
              setValuesForPagionation(querySnapshot);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //Todo: crear funcion para no duplicar codigo
        querySnapshotAux.forEach((doc) => {
          let temporaryParticipant = doc.data();
          temporaryParticipant.id = doc.id;
          addParticipant(temporaryParticipant);
        });
        props.notLoading();
        setIsEmpty(false);
        setIsLastPage(false);
      }
    } else {
      if (!isLastPage) {
        query.get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              props.notLoading();
              setIsEmpty(true);
              isLastPage = (currentPage > 1);
              setIsLastPage(isLastPage);
            } else {
              let hasParticipants = false;
              querySnapshot.forEach(async (doc) => {
                let assignationItem = doc.data();
                if (validateFilter(assignationItem)) {
                  hasParticipants = true;
                  let temporaryParticipant = (
                    await assignationItem.assigneeReference.get()
                  ).data();
                  let currentParticipant = {
                    city: temporaryParticipant.city,
                    currentStatus: temporaryParticipant.currentStatus,
                    id: doc.id,
                    name: temporaryParticipant.name,
                  };
                  currentParticipant.currentStatus.get().then((status) => {
                    let currentStatus = status.data();
                    let statusName = currentStatus.statusLabel;
                    currentParticipant.currentStatus = statusName;
                    currentParticipant.statusReference = status.ref.path;
                    currentParticipant.statusActive = currentStatus.active;
                    currentParticipant.city.get().then((city) => {
                      currentParticipant.city = city.data();
                      let joined = data;
                      joined.push(currentParticipant);
                      setData([]);
                      setData(joined);
                    });
                    props.notLoading();
                  });
                }
              });

              if (hasParticipants) {
                setQuerySnapshotAux(querySnapshot);
              }
              else
                setEmptyList();

              setIsEmpty(!hasParticipants);
              setValuesForPagionation(querySnapshot);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //Todo: crear funcion para no duplicar codigo
        querySnapshotAux.forEach(async (doc) => {
          let assignationItem = doc.data();
          let temporaryParticipant = (
            await assignationItem.assigneeReference.get()
          ).data();
          let currentParticipant = {
            city: temporaryParticipant.city,
            currentStatus: temporaryParticipant.currentStatus,
            id: doc.id,
            name: temporaryParticipant.name,
            nameLowerCase: temporaryParticipant.name.toLowerCase(),
          };
          currentParticipant.currentStatus.get().then((status) => {
            let currentStatus = status.data();
            let statusName = currentStatus.statusLabel;
            currentParticipant.currentStatus = statusName;
            currentParticipant.statusReference = status.ref.path;
            currentParticipant.statusActive = currentStatus.active;
            currentParticipant.city.get().then((city) => {
              currentParticipant.city = city.data();
              let joined = data;
              joined.push(currentParticipant);
              setData([]);
              setData(joined);
            });
            props.notLoading();
          });
        });
        props.notLoading();
        setIsEmpty(false);
        setIsLastPage(false);
      }
    }
  }

  const validateFilter = (itemParticipant) => {
    if (formRef.current.getFieldValue().name != undefined && formRef.current.getFieldValue().name != "" && formRef.current.getFieldValue().document != undefined && formRef.current.getFieldValue().document != "") {
      let objCalculate = calculateFilterId();
      return itemParticipant.id >= objCalculate.min && itemParticipant.id <= objCalculate.max;
    }
    else
      return true;
  }

  const setEmptyList = () => {
    setIsEmpty(true);
    isLastPage = (currentPage > 1);
    setIsLastPage(isLastPage);
  }

  const calculateFilterId = () => {
    let complet = 10 - formRef.current.getFieldValue().document.length;
    let min = parseInt(formRef.current.getFieldValue().document);
    let max = parseInt(formRef.current.getFieldValue().document) + 1;

    let completeNumber = 1;
    for (var i = 0; i < complet; i++) {
      completeNumber = completeNumber * 10;
    }

    return {
      min: (min * completeNumber),
      max: (max * completeNumber)
    }
  }

  const setValuesForPagionation = (querySnapshot) => {
    setFirstVisible(querySnapshot.docs[0]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  }


  const processArrayName = (name) => {
    let arrayName = [];
    for (var i = 0; i < name.length; i++) {
      let itemName = name.charAt(i);
      arrayName.push(itemName);
      if ((i + 1) < name.length) {
        for (var j = (i + 1); j < name.length; j++) {
          itemName += name.charAt(j);
          arrayName.push(itemName);
        }
      }
    }

    return arrayName;
  }

  const processFirebaseNames = async () => {
    const db = Firebase.firestore();

    await db.collection("cohorts").get()
      .then((querySnapshotCohort) => {
        querySnapshotCohort.docs.forEach(function (docCohort) {
          db.collection("cohorts").doc(docCohort.id)
            .collection("participants").get()
            .then((querySnapshot) => {
              querySnapshot.docs.forEach(function (doc) {
                let participant = doc.data();
                db.collection("cohorts")
                  .doc(UserStore.cohortSelected())
                  .collection("participants")
                  .doc(doc.id)
                  .update({ keyWordsName: processArrayName(participant.name.toLowerCase()) });
              });
            });
        });
      });
  }


  return (
    <>
      <Form
        // {...layout}
        layout="inline"
        name="nest-messages"
        onFinish={onFinish}
        ref={formRef}
        style={{ paddingBottom: '2em' }}
      >
        <Form.Item
          name={"name"}
          label="Digita el nombre"
          rules={[
            {
              required: false,
            },
          ]}
          style={{ width: 'calc(25% - 8px)' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"document"}
          label="Digita el documento"
          rules={[
            {
              required: false,
            },
          ]}
          style={{ width: 'calc(25% - 8px)' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          label="Estado"
          rules={[
            {
              required: false,
            },
          ]}
          style={{ width: 'calc(25% - 8px)' }}
        >
          <Select placeholder="Seleccione una Estado">
            {listStatus.map((item) =>
              <Option key={item.id} value={item.id}>{item.statusLabel}</Option>
            )}

          </Select>
        </Form.Item>
        <Button
          color="primary"
          size="sm"
          onClick={() => filterForm()}
        // style={{ display: 'inline-block', width: 'calc(20% - 8px)' }}
        >
          {" "}
            Filtrar{" "}
        </Button>
        <Button
          color="primary"
          size="sm"
          onClick={() => cleanFilters()}
          style={{ display: 'inline-block', marginLeft: '1em' }}
        >
          {" "}
            Limpiar{" "}
        </Button>
        {/* <Button
          color="primary"
          size="sm"
          onClick={() => processFirebaseNames()}
          style={{ display: 'inline-block', marginLeft: '1em' }}
        >
          {" "}
          processFirebaseNames{" "}
        </Button> */}

      </Form>
      {!props.loadingStatus && !isEmpty && (
        <SmarterTableComplete data={data} cols={tableColumns} paginationOptions={false}></SmarterTableComplete>
      )}
      {isEmpty && currentPage == 1 && (
        <Empty
          description={
            UserStore.getRole() === "admin"
              ? "No tiene participantes"
              : "No tiene participantes asignados"
          }
        />
      )}

      {isEmpty && currentPage > 1 && (
        <Empty
          description={
            "Esta Página no tiene participantes"
          }
        />
      )}

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {currentPage > 1 ? <Button
          color="primary"
          size="sm"
          onClick={() => getParticipants('prev')}>{"<"}
        </Button> : ""}
        <label style={{ padding: '1em' }}>
          {currentPage}
        </label>
        {!isEmpty ? <Button
          color="primary"
          size="sm"
          onClick={() => getParticipants('next')}>
          {">"}
        </Button> : ""}
      </div>

      <NewParticipant
        {...props}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        addParticipant={addParticipant}
      />
    </>
  );
};

export default Participants;

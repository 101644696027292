import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/firestore";


const config = {
    apiKey: "AIzaSyDqotHKo5AL4fh9joZ9WTa5tkETPxp-RLI",
    authDomain: "grpa-fn-rutamotor-prd.firebaseapp.com",
    databaseURL: "https://grpa-fn-rutamotor-prd.firebaseio.com",
    projectId: "grpa-fn-rutamotor-prd",
    storageBucket: "grpa-fn-rutamotor-prd.appspot.com",
    messagingSenderId: "283191126335",
    appId: "1:283191126335:web:d6d21b324cfae46ddc4b8a",
    measurementId: "G-GD2N1B70FF"
};

firebase.initializeApp(config);



if (process.env.NODE_ENV === 'development') {
    //firebase.auth().useEmulator('http://localhost:9099/');
    //firebase.firestore().useEmulator("localhost", 8080);
}



export default firebase;
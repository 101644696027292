import React, { useState, useEffect, useRef } from "react";
//import { Alert, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Spinner } from 'antd';
import { Alert, DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import UserStore from '../utilities/UserStore';
import Firebase from "../utilities/firebase";
import _ from 'lodash';

const NewParticipant = (props) => {

  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({ active: false, message: "", type: "" });

  const { Option } = Select;

  useEffect(() => {
    let db = Firebase.firestore();
    let iterator = 0;
    db.collection("cities")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let city = doc.data();
          console.log(city);
          city.id = doc.id;
          city.arrayPosition = iterator;
          setCities((cities) => [...cities, city]);
        });
        setLoading(false);
        iterator = 0;
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);



  const onParticipantCreation = async (nParticipant) => {

    let answer = { active: true, message: 'Se ha creado el participante con éxito', success: true, type: 'success' };
    const db = Firebase.firestore();

    let temp = _.toString(nParticipant.id);

    const usersRef = db.collection('cohorts').doc(UserStore.cohortSelected()).collection('participants').doc(temp);
    const userDoc = await usersRef.get();
    if (!userDoc.exists) {

      let userRef = db.doc('users/' + UserStore.getUserName());
      let cityRef = db.doc('cities/' + nParticipant.city);
      let statusRef = db.doc('status/1');
      let newParticipant = nParticipant;
      newParticipant.createdBy = userRef;
      newParticipant.created = Firebase.firestore.FieldValue.serverTimestamp();
      newParticipant.currentStatus = statusRef;
      newParticipant.city = cityRef;
      newParticipant.linkedProgramme = new Date(nParticipant.linkedProgramme);
      db.collection('cohorts').doc(UserStore.cohortSelected()).collection('participants').doc(temp).set(newParticipant);

      let visualizeObject = newParticipant;
      visualizeObject.id = temp;

      answer.object = visualizeObject;

    }
    else {
      answer = { active: true, message: 'El participante ya existe', success: false, type: 'error' };
    }

    return answer;
  }

  const onFinish = async (values) => {
    let uploadResult = await (onParticipantCreation(values));
    if (uploadResult.success) {
      props.addParticipant(uploadResult.object);
      props.setModalVisible(false);
      props.hasAlert(uploadResult);
      formRef.current.resetFields();
    }
    else {
      setAlert(uploadResult);
    }
  };



  const formRef = useRef(null);
  const handleOk = () => {

    formRef.current.submit();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <>
      {loading ? props.itsLoading() : (
        <>
          <Modal
            title="Agregar un participante"
            centered
            visible={props.modalVisible}
            onOk={handleOk}
            onCancel={() => props.setModalVisible(false)}
            width="50%"
          >
            {alert.active && (
              <>
                <Alert
                  type={alert.type}
                  message={alert.message}
                  closable
                  showIcon
                  style={{ zIndex: "100" }}
                />
              </>
            )}
            <Form
              {...layout}
              name="nest-messages"
              onFinish={onFinish}
              ref={formRef}
            >
              <Form.Item
                name={"name"}
                label="Digita el nombre"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="id"
                label="Documento de identidad"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="city"
                label="Ciudad de vinculación"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Seleccione una ciudad">
                  {cities.map((item) =>
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )}

                </Select>
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="linkedProgramme"
                label="Fecha de vinculación"
              >
                <DatePicker />
              </Form.Item>
            </Form>
          </Modal>


        </>
      )}
    </>
  );
};

export default NewParticipant;

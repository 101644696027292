import React from 'react';
import DownloadComponent from '../components/Downloader';

const UploadPage = (props) => {
    props.notLoading();
    return(<>
    <DownloadComponent {...props} />
    </>);
}

export default UploadPage;

import React from 'react';
import UploadComponent from '../components/Upload';

const UploadPage = (props) => {
    props.notLoading();
    return(<>
    <UploadComponent />
    </>);
}

export default UploadPage;

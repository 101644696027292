import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import Firebase from "../utilities/firebase";
import UserStore from "../utilities/UserStore";

import { AutoComplete, Card, Form, Descriptions, Empty, Input } from "antd";
import ProForm, {
  ProFormCheckbox,
  ProFormDependency,
  ProFormSelect,
  ProFormDigit,
  ProFormText,
  ProFormDatePicker,
  ProFormRadio,
  ProFormTextArea,
} from "@ant-design/pro-form";

import _ from "lodash";
//import citiesInfo from 'cities.json';
//import citiesInfo from 'all-the-cities';

const SmartForm = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({});
  const [cities, setCities] = useState([]);
  const [citiesAutocomplete, setCitiesAutocomplete] = useState([]);
  const [partcipantData, setParticipantData] = useState({});
  const [preFilled, setPreFilled] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [form] = Form.useForm();
  let controlCitySelected = {};
  let controlCitySelectedArray = [];

  const mount = async () => {
    if (props.evaluationID) {
      const db = Firebase.firestore();
      let evaluationInfo = await db
        .collection("cohorts")
        .doc(UserStore.cohortSelected())
        .collection("evaluations")
        .doc(props.evaluationID)
        .get();

      let currentEvaluation = {
        ...await evaluationInfo.data(),
        id: evaluationInfo.id,
      };

      let currentParticipant = (
        await evaluationInfo.data().information.participantId.get()
      ).data();
      setParticipantData(currentParticipant);

      props.notLoading();
      if (!_.isEmpty(currentEvaluation)) {
        if (_.findKey(currentEvaluation.items, { type: "city" })) {
          let citiesFetching = await fetch(
            "https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json"
          );
          let departmentsInfo = await citiesFetching.json();
          let allCities = [];
          _.forEach(departmentsInfo, (dept) => {
            allCities = _.merge(allCities, dept.ciudades);
          });

          let citiesFetch = await (await import("cities.json")).default;

          let citiesInfo = _.map(
            _.orderBy(_.filter(citiesFetch, { country: "CO" }), "name"),
            (city) => _.extend({ value: city.name }, city)
          );

          //TODO: mejorar funcion
          let uniqueCities = [...new Set(citiesInfo.map(obj => obj.value))];
          let uniqueCitiesMap = [...new Set(uniqueCities.map(obj => ({ value: obj })))];

          setCities(citiesInfo);
          setCitiesAutocomplete(uniqueCitiesMap);
        }

        setFormData(null);

        setFormData(await currentEvaluation);
        let test = await currentEvaluation.items;
        let tempItems = {};


        _.forEach(await test, (val, key) => {
          if (val.value) {
            tempItems[key] = val.value;
          }
        });

        setPreFilled(null);
        setPreFilled(await tempItems);

      }
    }

    if (props.showButton != undefined) {
      if (!props.showButton) {
        setShowSubmitButton(false);
      }
    }

  };
  useEffect(mount, []);



  useImperativeHandle(ref, () => ({

    sendForm() {
      form
        .validateFields()
        .then(values => {
          sentForm(values);
        })
    }

  }));

  const onSelect = (value, options) => {
    const db = Firebase.firestore();

    db.collection("cities")
      .where("name", "==", value)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          let cityRef = db.doc('cities/' + querySnapshot.docs[0].id);
          if (!controlCitySelectedArray.some(x => x.key == controlCitySelected.id)) {
            controlCitySelectedArray.push({
              key: controlCitySelected.id,
              value: cityRef,
              valueCity: value
            });
          } else {
            controlCitySelectedArray.filter(x => x.key == controlCitySelected.id)[0].value = cityRef;
          }
        } else {
          db.collection("cities").add(cities.filter(x => x.name == value)[0]);
          onSelect(value, options);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const citiesControlFocus = (control) => {
    controlCitySelected = control;
  }

  const citiesControlBlur = (control) => {
    controlCitySelected = null;
  }

  const citiesControlChange = (value) => {
    if (value == undefined || value == null || value == '') {
      debugger;
      if (controlCitySelectedArray.some(x => x.key == controlCitySelected.id)) {
        controlCitySelectedArray.filter(x => x.key == controlCitySelected.id)[0].value = null;;
      } else {
        controlCitySelectedArray.push({
          key: controlCitySelected.id,
          value: null,
          valueCity: ''
        });
      }
    }
  }

  const questionRenderer = (nQuestion) => {
    switch (nQuestion.type) {
      case "number":
        return (
          <>
            <ProFormDigit
              label={nQuestion.label}
              name={nQuestion.id}
              fieldProps={{ precision: 0 }}
              placeholder=""
              initialValue={nQuestion.value}
            />
          </>
        );
      case "text":
        return (
          <>
            <ProFormText
              name={nQuestion.id}
              label={nQuestion.label}
              placeholder=""
              initialValue={nQuestion.value}
            />
          </>
        );
      case "date":
        return (
          <>
            <ProFormDatePicker
              name={nQuestion.id}
              label={nQuestion.label}
              placeholder=""
              initialValue={nQuestion.value}

            />
          </>
        );
      case "select":
        let options = {};
        _.forEach(nQuestion.options, (option) => {
          options[option.key] = option.value;
        });
        return (
          <>
            <ProFormSelect
              name={nQuestion.id}
              label={nQuestion.label}
              valueEnum={options}
              placeholder=""
              initialValue={nQuestion.value}
            />
          </>
        );
      case "radio":
        //TODO: Update options
        return (
          <>
            <ProFormRadio.Group
              name={nQuestion.id}
              label={nQuestion.label}
              initialValue={nQuestion.value}
              options={[
                {
                  label: "item 1",
                  value: "a",
                },
                {
                  label: "item 2",
                  value: "b",
                },
                {
                  label: "item 3",
                  value: "c",
                },
              ]}
            />
          </>
        );
      case "email":
        //TODO: Validar que sea un correo elctrónico
        return (
          <>
            <ProFormText
              name={nQuestion.id}
              label={nQuestion.label}
              placeholder=""
              initialValue={nQuestion.value}
            />
          </>
        );
      case "checkbox":
        return (
          <>
            <ProFormCheckbox.Group
              layout="horizontal"
              name={nQuestion.id}
              label={nQuestion.label}
              options={_.map(nQuestion.options, "value")}
              initialValue={nQuestion.value}
            />
          </>
        );
      case "textarea":
        return (
          <>
            <ProFormTextArea
              name={nQuestion.id}
              label={nQuestion.label}
              placeholder=""
              initialValue={nQuestion.value}
            />
          </>
        );
      case "city":
        return (
          <>
            <Descriptions layout="vertical">
              <Descriptions.Item span={3} label={nQuestion.label}>
                <AutoComplete
                  name={nQuestion.id}
                  style={{ width: "100%" }}
                  options={citiesAutocomplete}
                  defaultValue={nQuestion.valueCity}
                  // Value={nQuestion.value}
                  placeholder=""
                  allowClear={true}
                  disabled={false}
                  onFocus={() => citiesControlFocus(nQuestion)}
                  onBlur={() => citiesControlBlur(nQuestion)}
                  onChange={citiesControlChange}
                  onSelect={onSelect}
                  filterOption={(inputValue, option) => {
                    return option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1;
                  }
                  }
                />
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      default:
        console.log(nQuestion.type);
    }
  };
  const sentForm = async (values) => {
    let temp = formData;

    _.forEach(values, (val, key) => {
      (temp.items[key]).value = val;
    });

    _.forEach(controlCitySelectedArray, (cityControl) => {
      (temp.items[cityControl.key]).value = cityControl.value;
      (temp.items[cityControl.key]).valueCity = cityControl.valueCity;
    });

    const db = Firebase.firestore();
    let docRef = db
      .collection("cohorts")
      .doc(UserStore.cohortSelected())
      .collection("evaluations")
      .doc(props.evaluationID);


    console.log("temp.items");
    console.log(temp.items);

    // Update the timestamp field with the value from the server
    var update = docRef.update({
      items: temp.items
    });
    console.log(await update);

    return ((await update) ? '404' : '200');

  };
  return (
    <>
      {_.isEmpty(formData) ? (
        <Empty description="No hay datos de esta evaluación" />
      ) : (
        <>
          <Card
            headStyle={{
              position: "sticky",
              top: "-25px",
              zIndex: 100,
              backgroundColor: "white",
            }}
            title={
              formData.information.evaluationName + " | " + partcipantData.name
            }

            style={{ width: "100%" }}
          >

            <ProForm
              initialValues={preFilled}
              form={form}
              onFinish={async (values) => {
                let answer = await sentForm(values);

                return answer;

              }}
              submitter={{
                // Configure the button text
                searchConfig: {

                  submitText: 'Guardar',
                },
                submitButtonProps: {
                  style: {
                    display: !showSubmitButton ? 'none' : 'inherit'
                  }
                },
                // Configure the properties of the button
                resetButtonProps: {
                  style: {
                    // Hide the reset button
                    display: 'none',
                  },
                },

              }}
            >
              {_.map(formData.items, (question, key) => {
                return questionRenderer({ ...question, id: key });
              })}
            </ProForm>
          </Card>
        </>
      )}
    </>
  );
});

export default SmartForm;

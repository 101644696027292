import React, {useState, useEffect} from "react";
import SmartForm from "../components/SmartForm";


const Evaluation = (props) => {
  
  


  return (
    <>
      <SmartForm {...props} evaluationID={props.location.state.evaluation} />
    </>
  );
};

export default Evaluation;

import React from "react";

import { Col, Layout, Row } from "antd";
import neme from "../assets/brand/fn.png";
const { Footer } = Layout;

const FullFooter = (props) => {
  return (
    <>
      <Footer
        className={props.className}
        style={{ backgroundColor: "transparent" }}
      >
        <Row align={"middle"}>
          <Col span={8}>
            <img alt='Logo Fundación Neme' style={{ maxHeight: "60px" }} src={neme} />
          </Col>
          <Col style={{ textAlign: "right" }} span={8} offset={8}>
            ©{new Date().getFullYear()}
            <a
              className="font-weight-bold ml-1"
              href="https://www.fundacionneme.org/la-fundacion/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Fundación Neme
            </a>
          </Col>
        </Row>
      </Footer>
    </>
  );
};

export default FullFooter;

import { CodepenSquareFilled, ControlOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import SmartForm from '../components/SmartForm';
import React, { useEffect, useState, useRef } from "react";
import UserStore from "../utilities/UserStore";
import Firebase from "../utilities/firebase";
import Evaluation from "../pages/Evaluation"
import { Alert } from 'antd';
import _ from "lodash";
import {
  Modal,
  Button,
  Card,
  Col,
  Descriptions,
  Dropdown,
  Menu,
  Row,
  Steps,
  Space,
  PageHeader,
} from "antd";

import { PlusOutlined, RetweetOutlined, QuestionOutlined } from "@ant-design/icons";

import SmarterTable from "../components/SmarterTable";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const { Step } = Steps;
const { confirm } = Modal;

const DetailsPage = (props) => {
  const [participant, setParticipant] = useState({
    id: "",
    city: {},
    createdBy: {},
    currentStatus: {},
    nextStatus: [],
    participantEvaluations: [],
    possibleEvaluations: [],
  });

  const [visibleAlert, setVisibleAlert] = useState(false);
  var [typeAlert, setTypeAlert] = useState([]);
  var [listStateChange, setListStateChange] = useState([]);
  var [listAllStatus, setListAllStatus] = useState([]);

  const smartFormRef = useRef();
  const [evaluationId, setEvaluationId] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  var [statusTimeline, setStatusTimeline] = useState([]);
  const [evaluationsArray, setEvaluationsArray] = useState([]);
  const [nextStatusSelected, setNextStatusSelected] = useState(0);

  const mount = async () => {
    statusTimeline = [];
    setStatusTimeline(statusTimeline);

    typeAlert = [];
    setTypeAlert(typeAlert);

    props.noTitle();

    props.itsLoading();
    if (props.location && props.location.state) {
      const db = Firebase.firestore();
      let basicInfo = await db
        .collection("cohorts")
        .doc(UserStore.cohortSelected())
        .collection("participants")
        .doc(_.toString(props.location.state.participant))
        .get();

      let allInfo = basicInfo.data();
      allInfo.id = basicInfo.id;
      allInfo.city = (await basicInfo.data().city.get()).data();
      if (basicInfo.data().createdBy != "")
        allInfo.createdBy = (await basicInfo.data().createdBy.get()).data();

      let stateToTimeline = [];

      let statusInfo = await basicInfo.data().currentStatus.get();
      allInfo.currentStatus = { ...statusInfo.data(), id: statusInfo.id };

      if (allInfo.currentStatus.id === "1") {
        let temp = statusTimeline;
        temp.push({
          ...allInfo.currentStatus,
          dateToStatus: allInfo.created,
          currentStatus: "true",
          pastStatus: false,
        });
        setStatusTimeline([]);
        setStatusTimeline(temp);
      }

      let nextStatusArray = [];
      if (allInfo.currentStatus.active) {
        if (allInfo.currentStatus.nextStatus) {
          await Promise.all(
            _.forEach(allInfo.currentStatus.nextStatus, async (nextStatus) => {

              let nextCall = await nextStatus.get();
              nextStatusArray.push({ ...nextCall.data(), id: nextCall.id });
              let temp = statusTimeline;

              temp.push({
                ...nextCall.data(),
                id: nextCall.id,
                currentStatus: false,
                pastStatus: false,
              });
              setStatusTimeline([]);
              setStatusTimeline(temp);
            })
          );
        }

      }


      allInfo.currentStatus.nextStatus = await nextStatusArray;

      let participantRef = db.doc(
        "cohorts/" +
        UserStore.cohortSelected() +
        "/participants/" +
        props.location.state.participant
      );
      let evaluationsInfo = await db
        .collection("cohorts")
        .doc(UserStore.cohortSelected())
        .collection("evaluations")
        .where("information.participantId", "==", participantRef)
        .get();

      let evaluationsArray = [];

      if (!evaluationsInfo.empty) {
        evaluationsInfo.forEach(async (doc) => {
          evaluationsArray.push({ ...doc.data(), id: doc.id });
        });
      }

      allInfo.participantEvaluations = evaluationsArray;

      allInfo.evaluations = evaluationsArray;

      let statusRef = db.doc("status/" + allInfo.currentStatus.id);

      let possibleEvaluationsInfo = await db
        .collection("evaluations")
        .where("information.evaluationCondition.field", "==", "currentStatus")
        .where(
          "information.evaluationCondition.possibleValues",
          "array-contains-any",
          [statusRef]
        )
        .get();

      let possibleEvaluations = [];

      if (!possibleEvaluationsInfo.empty) {
        possibleEvaluationsInfo.forEach(async (doc) => {
          let currentPossibility = await doc.data();
          currentPossibility.id = doc.id;

          currentPossibility.selectable =
            currentPossibility.information.evaluationFrequency === "once"
              ? _.find(allInfo.evaluations, (evaluation) => {
                return evaluation.information.typeId == currentPossibility.id;
              })
                ? false
                : true
              : true;

          possibleEvaluations.push(currentPossibility);
        });
      }

      setEvaluationsArray(await possibleEvaluations);

      allInfo.possibleEvaluations = await possibleEvaluations;

      if (allInfo.currentStatus.id == "1") {
        let temp = {
          ...allInfo.currentStatus,
          dateToStatus: allInfo.created,
          currentStatus: true,
        };
        stateToTimeline.push(temp);
      } else {
        let stateHistory = await db
          .collection("cohorts")
          .doc(UserStore.cohortSelected())
          .collection("participants")
          .doc(_.toString(props.location.state.participant))
          .collection("stateChanges")
          .get();

        if (!stateHistory.empty) {
          stateHistory.forEach(async (doc) => {
            let currentHistory = doc.data();
            currentHistory.id = doc.id;

            let promiseArray = [
              { object: currentHistory.newState, type: "newState" },
              { object: currentHistory.pastState, type: "pastState" },
            ];

            await Promise.all(
              promiseArray.map(async (promise) => {
                let promiseAnswer = await promise.object.get();

                if (!listStateChange.some(x => x.id == promiseAnswer.id))
                  listStateChange.push({
                    ...promiseAnswer.data(),
                    id: promiseAnswer.id,
                  });

                if (promise.type === "newState") {
                  currentHistory.newState = {
                    ...promiseAnswer.data(),
                    id: promiseAnswer.id,
                  };
                  let temp = statusTimeline;
                  temp.push({
                    ...currentHistory.newState,
                    dateToStatus: currentHistory.created,
                    currentStatus:
                      currentHistory.newState.id === allInfo.currentStatus.id
                        ? "true"
                        : false,
                    pastStatus: true,
                  });
                  setStatusTimeline([]);
                  setStatusTimeline(temp);
                } else {
                  currentHistory.pastState = {
                    ...promiseAnswer.data(),
                    id: promiseAnswer.id,
                  };
                }
              })
            );
          });
        }
      }

      allInfo.stateToTimeline = stateToTimeline;

      setParticipant({});
      setParticipant(allInfo);
      //Si envio el botón al template no se actualiza el estado...
      //props.hasTitle(allInfo.name, button);
      props.notLoading();
    } else {
      //TODO: Si llegan a esta página sin haber navegado y por consiguiente seleccionar un participante, lanzar error y enviarlos a la última página.
      alert("tiene que seleccionar un participante");
    }
  };

  useEffect(mount, []);


  const newEvaluation = async (e) => {
    let db = Firebase.firestore();

    let participantRef = await db.doc(
      "cohorts/" +
      UserStore.cohortSelected() +
      "/participants/" +
      _.toString(props.location.state.participant)
    );
  }

  const selectNewEvaluation = async (e) => {


    let availableEvaluations = participant.possibleEvaluations;

    let selectedEvaluation = _.filter(availableEvaluations, { id: e.key })[0];

    newTask(selectedEvaluation);


  }

  const newTask = async (e) => {
    let db = Firebase.firestore();
    let selectedEvaluation = e;
    let availableEvaluations = participant.possibleEvaluations;
    let creatorRef = await db.doc("users/" + UserStore.getUserName());
    let participantRef = await db.doc(
      "cohorts/" +
      UserStore.cohortSelected() +
      "/participants/" +
      _.toString(props.location.state.participant)
    );

    let evaluationToUpload = { items: selectedEvaluation[selectedEvaluation.information.currentVersion], information: selectedEvaluation.information };
    evaluationToUpload.information = {
      ...evaluationToUpload.information,
      created: Firebase.firestore.Timestamp.fromDate(new Date()),
      createdBy: creatorRef,
      finished: false,
      participantId: participantRef,
      started: false,
      status: "Nueva tarea",
      typeId: e.key || e.id,
    };

    let uploaded = await db.collection('cohorts').doc(UserStore.cohortSelected()).collection('evaluations').add(evaluationToUpload);
    if (uploaded.id) {

      evaluationToUpload.id = uploaded.id;
      let currentParticipant = participant;
      let evaluationsArray = currentParticipant.evaluations;
      evaluationsArray.push(evaluationToUpload);
      currentParticipant.evaluations = evaluationsArray;

      availableEvaluations[
        _.findIndex(availableEvaluations, { id: e.key })
      ] = selectedEvaluation;
      participant.possibleEvaluations = availableEvaluations;

      availableEvaluations[
        _.findIndex(availableEvaluations, { id: e.key })
      ] = selectedEvaluation;
      participant.possibleEvaluations = availableEvaluations;

      setParticipant({});
      setParticipant(currentParticipant);
    }

    return uploaded.id;
  };

  const changeStatus = async (e) => {
    props.itsLoading();
    setNextStatusSelected(e.key);

    let statusSelected = _.filter(participant.currentStatus.nextStatus, { 'id': e.key })[0];
    if (statusSelected.evaluationReference) {
      let evaluationRequest = (await statusSelected.evaluationReference.get());
      let evaluationInfo =
      {
        ...evaluationRequest.data(),
        key: evaluationRequest.id
      };

      if (statusSelected.evaluationAssociated) {
        let newEvaluationId = await newTask(evaluationInfo);
        setEvaluationId(newEvaluationId);
      }
    }

    setIsOpenModal(true);
    setModalContent(`Estado actual ${participant.currentStatus.statusLabel} - estado nuevo ${statusSelected.statusLabel}`);
    props.notLoading();
  };

  const onOkModal = async () => {
    //TODO current is undefined no puede ejecuar la funcion
    if (smartFormRef.current) {
      var response = await smartFormRef.current.sendForm();
    }
    changeStatusDataBase(participant.currentStatus.id, nextStatusSelected);
    setIsOpenModal(false);
    //status 200 => limpiar modal y cerrarlo 
    //status 400 falto algo   
  }

  const validateStatus = async (e) => {
    props.itsLoading();

    let evaluationReferneces = null;

    if (participant.currentStatus.evaluationReference != undefined) {
      evaluationReferneces = await participant.currentStatus.evaluationReference.get();
    }

    let pastStatus = _.filter(statusTimeline, { pastStatus: true })
    let listStatus = [];

    const db = Firebase.firestore();
    await db.collection("status").get().then(async (querySnapshot) => {
      querySnapshot.docs.forEach(function (doc) {
        listStatus.push({
          ...doc.data(),
          id: doc.id,
          prevStatusData: []
        });
      });
    });

    listAllStatus = listStatus;

    //list evaluations for validate current status and starus change participant
    let listEvaluations = [];
    await db.collection("evaluations").get().then(async (querySnapshot) => {
      querySnapshot.docs.forEach(function (doc) {
        
        listEvaluations.push({
          ...doc.data(),
          id: doc.id,
          refrences: db.doc("evaluations/" + doc.id)
        });
      });
    });

    //Validate evaluations by statuschanges in user
    let evaluationsRequired = [];
    for (const currentStatusIterate of pastStatus) {
      let objectValidate = await validateStatusEvaluationRequired(currentStatusIterate, listStatus);
      if (objectValidate != null) {
        if (evaluationReferneces != null)
          evaluationsRequired.push({ ...objectValidate, isError: evaluationReferneces.data().information.evaluationName != objectValidate.information.evaluationName, isWarning: evaluationReferneces.data().information.evaluationName == objectValidate.information.evaluationName });
        else
          evaluationsRequired.push({ ...objectValidate, isError: false, isWarning: true });
      }
    }

    //TODO: mirar si se puede optimizar por una consulta de firebase con los arreglos de los estados que ha pasado el participante
    //Validate listEvlauations possibleValues status
    for (const currentEvaluation of listEvaluations) {
      if (currentEvaluation.information.evaluationCondition && currentEvaluation.information.evaluationCondition.field == "currentStatus") {
        for (const doc of currentEvaluation.information.evaluationCondition.possibleValues) {
          let objectValidate = await validateEvaluationRequired(doc, listStateChange, currentEvaluation);
          if (objectValidate != null) {

            if (objectValidate.warning && evaluationsRequired.some(x => x.id == objectValidate.id && x.isError)) {
              _.filter(evaluationsRequired, { id: objectValidate.id })[0].isError = false;
              _.filter(evaluationsRequired, { id: objectValidate.id })[0].isWarning = objectValidate.warning;
            }
            else if (!evaluationsRequired.some(x => x.id == objectValidate.id)) {
              evaluationsRequired.push({ ...objectValidate, isError: objectValidate.error, isWarning: objectValidate.warning });
            }
          }
        }
      }
    }

    if (evaluationsRequired.length == 0) {
      showAlert("El participante tiene todas las tareas que corresponde", "success");
    } else {
      let nameEvaluationsError = "";
      let nameEvaluationsWarning = "";

      _.forEach(evaluationsRequired, (itemEvaluationRequired) => {
        if (itemEvaluationRequired.isError)
          nameEvaluationsError += (nameEvaluationsError != "" ? ", " : "") + itemEvaluationRequired.information.evaluationName;
        else
          nameEvaluationsWarning += (nameEvaluationsWarning != "" ? ", " : "") + itemEvaluationRequired.information.evaluationName;
      });

      if (nameEvaluationsError != "")
        showAlert("Faltan las tareas " + nameEvaluationsError + "del participante", "error");
      if (nameEvaluationsWarning != "")
        showAlert("Faltan las tareas " + nameEvaluationsWarning + "del participante", "warning");

      //insert in firebase validation data
      const db = Firebase.firestore();

      let creatorRef = db.doc("users/" + UserStore.getUserName());
      db.collection("cohorts")
        .doc(UserStore.cohortSelected())
        .collection("participants")
        .doc(participant.id)
        .collection("stateValidations")
        .add({
          evaluationError: _.filter(evaluationsRequired, { isError: true }).map(x => x.evaluationReference),
          evaluationWarning: _.filter(evaluationsRequired, { isWarning: true }).map(x => x.evaluationReference),
          created: Firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: creatorRef,
          type: "manual" //TODO: set by parameter
        });
    }

    props.notLoading();
  }

  const validateStatusEvaluationRequired = async (currentStatusIterate, listStatus) => {
    let currentStatus = _.filter(listStatus, { id: currentStatusIterate.id })[0];
    //Validate if participant has evaluations
    if (currentStatus.evaluationAssociated) {
      let evaluationRequest = await currentStatus.evaluationReference.get();
      if (!_.some(participant.evaluations, (x) => x.information.typeId === evaluationRequest.id)) {
        return ({ ...evaluationRequest.data(), status: "Falta evaluación", evaluationReference: currentStatus.evaluationReference });
      }
    }
  }

  const validateEvaluationRequired = async (doc, listStateChange, currentEvaluation) => {
    let itemStatusEvaluation = { id: doc.id };

    if (listStateChange.length > 0) {
      if (_.some(listStateChange, (x) => x.id === itemStatusEvaluation.id)) {
        if (!participant.evaluations.some(x => x.information.typeId == currentEvaluation.id)) {
          if (itemStatusEvaluation.id != participant.currentStatus.id) {
            return { ...currentEvaluation, status: "Falta evaluación", error: true, warning: false, evaluationReference: currentEvaluation.refrences };
          } else if (itemStatusEvaluation.id == participant.currentStatus.id) {
            return { ...currentEvaluation, status: "Falta evaluación", error: false, warning: true, evaluationReference: currentEvaluation.refrences };
          }
        }
      }
    }
  }

  const showAlert = (message, type) => {
    setVisibleAlert(true);

    let typeAlertTemp = typeAlert;
    typeAlertTemp.push({
      type: type,
      message: message
    });

    setTypeAlert([]);
    setTypeAlert(typeAlertTemp);
  };

  const handleClose = () => {
    setVisibleAlert(false);
  };

  function changeStatusDataBase(currentStatusId, nextStatusId) {
    props.itsLoading();

    const db = Firebase.firestore();

    let nextStatusSelectedRef = db.doc("status/" + nextStatusId);
    let currentStatusRef = db.doc("status/" + currentStatusId);
    let creatorRef = db.doc("users/" + UserStore.getUserName());

    db.collection("cohorts")
      .doc(UserStore.cohortSelected())
      .collection("participants")
      .doc(participant.id)
      .update({ currentStatus: nextStatusSelectedRef });

    db.collection("cohorts")
      .doc(UserStore.cohortSelected())
      .collection("participants")
      .doc(participant.id)
      .collection("stateChanges")
      .add({
        pastState: currentStatusRef,
        newState: nextStatusSelectedRef,
        created: Firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: creatorRef,
      });


    mount();
  }

  //TODO 3: tipo 3 ejemplo, ejmeplo evaluacion 3. mostrar solo las de current status y si once y si no esta llena
  // la 6 el ejemplo de rosenberg
  // dentro de evaluacion hay variable que se llama evaluation frequency cuantas veces 
  //puede existir esa evaluacion
  // si dice once una vez 
  const evaluationMenu = (
    <Menu>
      {_.map(participant.possibleEvaluations, (evaluation) => {
        return (
          <>
            <Menu.Item
              onClick={selectNewEvaluation}
              key={evaluation.id}
              disabled={!evaluation.selectable}
            >
              {evaluation.information.evaluationName}
            </Menu.Item>
          </>
        );
      })}
    </Menu>
  );

  const statusMenu = (
    <Menu>
      {participant.currentStatus
        ? _.map(participant.currentStatus.nextStatus, (status) => {
          return (
            <>
              <Menu.Item onClick={changeStatus} key={status.id}>
                {status.statusLabel}
              </Menu.Item>
            </>
          );
        })
        : ""}
    </Menu>
  );

  const tableColumns = [
    {
      title: "ID de la evaluación",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre de la evaluación",
      dataIndex: ["information", "evaluationName"],
      key: "name",

      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: "Creada en",
      dataIndex: "date",
      key: "date",
      render: (record) => (
        <Moment local format="DD MMM YYYY">
          {record.information.created.toDate()}
        </Moment>
      ),
    },
    {
      title: "Estado",
      dataIndex: ["information", "status"],
      key: "currentStatus",

      shouldFilter: true,
      shouldSort: true,
    },
    {
      title: "Action",
      dataIndex: "render",
      key: "x",
      render: (record) => (
        <Link
          to={{
            pathname: "/evaluation",
            state: { evaluation: record.id },
          }}
        >
          <Button color="primary" size="sm">
            {" "}
            Detalles{" "}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header-responsive page-header-rm"
        onBack={() => window.history.back()}
        title={participant.name}
        extra={
          <>
            <Dropdown overlay={evaluationMenu} placement="bottomLeft">
              <Button type="primary" icon={<PlusOutlined />}>
                {" "}
                Nueva tarea{" "}
              </Button>
            </Dropdown>

            <Dropdown overlay={statusMenu} placement="bottomLeft">
              <Button type="secondary" icon={<RetweetOutlined />}>
                {" "}
                Cambiar estado{" "}
              </Button>
            </Dropdown>
            <Button type="secondary" icon={<QuestionOutlined />} onClick={validateStatus} >
              {" "}
              Validar estado{" "}
            </Button>
          </>
        }
      />
      <div>
        {visibleAlert && _.filter(typeAlert, { type: "error" }).length > 0 ? (
          <Alert message={_.filter(typeAlert, { type: "error" })[0].message} type="error" afterClose={handleClose} />
        ) : null}
        {visibleAlert && _.filter(typeAlert, { type: "warning" }).length > 0 ? (
          <Alert message={_.filter(typeAlert, { type: "warning" })[0].message} type="warning" closable afterClose={handleClose} />
        ) : null}
        {visibleAlert && _.filter(typeAlert, { type: "success" }).length > 0 ? (
          <Alert message={_.filter(typeAlert, { type: "success" })[0].message} type="success" closable afterClose={handleClose} />
        ) : null}
      </div>
      <Row gutter={[16, 16]}>
        <Col key="basic1" span={8}>
          <Card
            key="basicc"
            title={
              <span style={{ fontWeight: "bold" }}>Información general</span>
            }
          >
            <Descriptions>
              <Descriptions.Item span={3} label="Documento de identidad">
                {participant.id}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Ciudad de vinculatión">
                {participant.city && participant.city.name}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col key="basic" span={16}>
          <Card
            key="basic3"
            className="timeline-card"
            title={
              <span style={{ fontWeight: "bold" }}>Historial de status</span>
            }
          >
            <Steps
              progressDot
              current={
                _.filter(_.sortBy(statusTimeline, "id"), { pastStatus: true })
                  .length - 1
              }
            >
              {_.map(
                _.filter(_.sortBy(statusTimeline, "id"), {
                  pastStatus: true,
                  currentStatus: false,
                }),
                (status) => {
                  return (
                    <>
                      <Step
                        title={status.statusLabel}
                        description={
                          <>
                            <Moment local format="DD MMM YYYY">
                              {status.dateToStatus.toDate()}
                            </Moment>
                          </>
                        }
                      />
                    </>
                  );
                }
              )}
              {_.map(
                _.filter(_.sortBy(statusTimeline, "id"), {
                  currentStatus: "true",
                }),
                (status) => {
                  return (
                    <>
                      <Step
                        title={status.statusLabel}
                        description={
                          <>
                            <Moment local format="DD MMM YYYY">
                              {status.dateToStatus.toDate()}
                            </Moment>
                          </>
                        }
                      />
                    </>
                  );
                }
              )}
              {_.filter(statusTimeline, {
                pastStatus: false,
                currentStatus: false,
              }).length > 0 ? (
                <>
                  <Step
                    title="Posibles estados"
                    description={_.map(
                      _.filter(_.sortBy(statusTimeline, "id"), {
                        pastStatus: false,
                        currentStatus: false,
                      }),
                      (status) => {
                        return <li>{status.statusLabel}</li>;
                      }
                    )}
                  />
                </>
              ) : (
                console.log("no tengo")
              )}
            </Steps>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col key="evaluationscolumn" span={24}>
          <Card
            key="evaluationscard"
            title={
              <span style={{ fontWeight: "bold" }}>
                Evaluaciones del participante
              </span>
            }
          >
            <SmarterTable data={participant.evaluations} cols={tableColumns} />
          </Card>
        </Col>
      </Row>
      <Modal
        onOk={onOkModal}
        visible={isOpenModal} title="Title"
        onCancel={() => setIsOpenModal(false)}
      >
        {_.isEmpty(evaluationId) ? modalContent : (<>{modalContent} <SmartForm ref={smartFormRef} {...props} evaluationID={evaluationId} showButton={false} /> </>)}</Modal>
    </>
  );
};

export default DetailsPage;

